export const ICONS = {
    PLUG: "media/devices/plug-fill.png",
    SWITCH: "media/devices/wall-switch1.png",
    BULB: "media/devices/light-bulb.png",
    STONE: "media/devices/circuit-board.png",
    THERMOSTAT: "media/devices/thermo.png",
    MOTOR: "media/devices/belt.png",
    DOOR_LOCK: "media/devices/door-lock.png",
    CAMERA: "media/devices/security-camera.png",
    GENERIC: "media/devices/command-line.png",
    GPSV1: "media/devices/gps.png",
    SEISMO: "media/devices/seismo.png",
    ENERMON: "media/devices/power-meter64_2.png",
    SOILLESSV1: "media/devices/soil-sensor64_2.png",
    TEMPLESSV1: "media/devices/temp-sensor64_2.png",
    TRACKERV1: "media/devices/tracker-sensor64_1.png",
}
export const ICONS_TECHNICAL = {
    camv1: ICONS.CAMERA,
    sfir: ICONS.SWITCH,
    sfir_alpha0: ICONS.SWITCH,
    suitchv3: ICONS.STONE,
    thermostatv1: ICONS.THERMOSTAT,
    bulbv1: ICONS.BULB,
    null: ICONS.GENERIC,
    gpsv1: ICONS.GPSV1,
    doorv1: ICONS.DOOR_LOCK,
    seismov1: ICONS.SEISMO,
    enermonv1: ICONS.ENERMON,
    soilblessv1: ICONS.SOILLESSV1,
    templessv1: ICONS.TEMPLESSV1,
    trackerv1: ICONS.TRACKERV1,
}