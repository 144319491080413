<template>
  <div class="card card-custom card-stretch gutter-b bg-light-info">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">{{$t('Dashboard.Otherspublicdevices')}}</h3>
      <div class="card-toolbar">
        <Dropdown4
          :btnClass="
            'btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle'
          "
          :btnText="'August'"
        ></Dropdown4>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <div class="d-flex justify-content-center mb-3" v-if="list == null">
        <b-spinner variant="primary"> </b-spinner>
      </div>
      <template v-for="(item, i) in list">
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 symbol-light-success mr-5">
            <span class="symbol-label">
              <img :src="getIconPath(item.version)" class="h-75 align-self-end" alt="" />
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
            <a
              href="#"
              class="text-dark text-hover-primary mb-1 font-size-lg"
              v-on:click.prevent="goToDetails(item.version, item.token)"
              >{{ item.object }}</a
            >
            <span class="text-muted">{{ item.description }}</span>
          </div>
          <!--end::Text-->
          <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
        </div>
      </template>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown4 from "@/view/content/dropdown/Dropdown4.vue";
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { FETCH_PUBLIC_DEVICES } from "@/core/services/store/devices.module"
import { ICONS_TECHNICAL } from "@/core/enums/icons.enum"
export default {
  name: "public-devices-widget",
  components: {
    Dropdown4,
    Dropdown2
  },
  mounted() {
    this.$store.dispatch(FETCH_PUBLIC_DEVICES).then((inlist)=>{
      this.list = inlist;
    });
  },
  data() {
    return {
      getIconPath(deviceVersion){
        return ICONS_TECHNICAL[deviceVersion];
      },
      list: null,
    };
  },
  methods: {
    goToDetails(deviceVersion, token) {
      switch (deviceVersion) {
        case null:
          this.$router.push({
            name: "device-generic",
            params: {
              token: token,
            },
          });
          break;
        case "camv1":
          this.$router.push({
            name: "device-camera",
            params: {
              token: token,
            },
          });
          break;
        case "sfir":
          this.$router.push({
            name: "device-sfir",
            params: {
              token: token,
            },
          });
          break;
          case "thermostatv1":
          this.$router.push({
            name: "device-thermo",
            params: {
              token: token,
            },
          });
          break;
      }
    },
  }
};
</script>
