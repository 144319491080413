<template>
  <div class="card card-custom gutter-b card-stretch card-shadowless bg-light">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t('Dashboard.Locationofdevices') }}
        </span>
      </h3>
      <div class="card-toolbar">
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <GmapMap :center='center' :zoom='12' ref="map" style='width:100%;  height: 400px;'>
        <GmapMarker :position="center" :zoom="1"
          :icon="{ url: require('../../../../../public/media/markers/man2.png') }" />
        <GmapMarker v-for="(m, index) in markers" :key="index" :position="m.location" :clickable="true" :draggable="true"
          :icon="{ url: require('../../../../../public/media/markers/bot.png') }" />
      </GmapMap>

      <!--end::Table-->
    </div>
    <!--end::Body-->
    <b-modal ref="map-denied" hide-footer title="Oops!">
      <div class="d-block text-center">
        The map feature requires permissions to your browser location, please enable location to locate the map properly.
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { UPDATE_USER_POSITION } from "@/core/services/store/profile.module"
export default {
  name: "suitch-list-device-location",
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      show: "day",
    }
  },
  components: {
  },
  watch: {
    markers(markers) {
      if (markers.length > 2) {
        const bounds = new window.google.maps.LatLngBounds();
        for (let m of markers) {
          bounds.extend(m.location);
        }
        this.$refs.map.fitBounds(bounds);
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getLocationPermission();
  },
  methods: {
    getLocationPermission() {
      // Check for Geolocation API permissions
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          // The permission was granted, you can get the geolocation
          this.geolocate();
        } else if (result.state === 'prompt') {
          // The permission is not decided yet, you can prompt the user
          this.geolocate();
        } else if (result.state === 'denied') {
          // The permission was denied
          this.$refs['map-denied'].show();
          console.log("denied access to map");
        }
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.$store
          .dispatch(UPDATE_USER_POSITION, { location: `${position.coords.latitude},${position.coords.longitude}` })
          // go to which page after successfully login
          .then((data) => {
            this.markers = data;
            const bounds = new window.google.maps.LatLngBounds();
            if (this.markers.length > 2) {
              for (let m of this.markers) {
                bounds.extend(m.location);
              }
              this.$refs.map.fitBounds(bounds);
            }
          })
          .catch(() => { });
        // also add a marker of the person
      });
    },
  }
};
</script>
