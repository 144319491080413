<template>

    <!--begin::Nav Tabs-->
    <div v-if="fav_devices.length == 0">
      <template v-if="dev_Loaded == null" > 
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"
        >
        </b-spinner>
      </div>
    </template>
      <b-jumbotron :header="$t('Dashboard.Favouritedevices')" lead="No favourite devices yet" v-else>
      </b-jumbotron>
    </div>
    <div
    class="card card-custom bg-light-warning gutter-b card-stretch card-shadowless "
    v-else>
    <template v-if="dev_Loaded == null" > 
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"
        >
        </b-spinner>
      </div>
    </template>
    <div class="card-header border-0 pt-5" v-else>
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{$t('Dashboard.Favouritedevices')}}
        </span>
      </h3>
    </div>
    <ul
      class="dashboard-tabs nav nav-pills nav-danger row row-paddingless m-6 p-0 flex-column flex-sm-row"
      role="tablist"
    >
      <!--begin::Item-->
      <li
        class="nav-item d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0"
        v-for="n in fav_devices.length"
        v-bind:key="n"
        
      >
        <a
          class="nav-link border py-60 d-flex flex-grow-1 rounded flex-column align-items-center"
          data-toggle="pill"
          href="#tab_forms_widget_1"
        >
          <span class="nav-icon py-2 w-auto">
            <span class="svg-icon svg-icon-3x">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
              <img :src="getIconPath(fav_devices[n-1].version)" class="align-self-end" alt="" />
              <!--end::Svg Icon-->
            </span>
          </span>
          <span class="nav-text font-size-lg py-2 font-weight-bold text-center"
            v-on:click.prevent="goToDetails(fav_devices[n-1].version, fav_devices[n-1].token)"
            font style="text-transform: capitalize"
            > {{ fav_devices[n-1].object }}</span
          >
          <span class="text-muted">{{ fav_devices[n-1].description }}</span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <!--end::Item-->
    </ul>
    <!--end::Nav Tabs-->
    <!--begin::Nav Content-->
    <div class="tab-content m-0 p-0">
      <div
        class="tab-pane active"
        id="forms_widget_tab_1"
        role="tabpanel"
      ></div>
      <div class="tab-pane" id="forms_widget_tab_2" role="tabpanel"></div>
      <div class="tab-pane" id="forms_widget_tab_3" role="tabpanel"></div>
      <div class="tab-pane" id="forms_widget_tab_4" role="tabpanel"></div>
      <div class="tab-pane" id="forms_widget_tab_6" role="tabpanel"></div>
    </div>
    <!--end::Nav Content-->
  </div>
</template>

<script>
import { GET_FAVOURITE_DEVICES } from "@/core/services/store/devices.module";
import { ICONS_TECHNICAL } from "@/core/enums/icons.enum"
export default {
  name: "suitch-fav-devices-widget",
  mounted() {
    this.$store
      .dispatch(GET_FAVOURITE_DEVICES)
      // go to which page after successfully login
      .then((data) => {
        // download devices list
        this.fav_devices = data;
        this.dev_Loaded = data;
      });
  },
  data() {
    return {
      getIconPath(deviceVersion){
        return ICONS_TECHNICAL[deviceVersion];
      },
      fav_devices: [],
      dev_Loaded: null,
      list: [
        {
          title: "Top Authors",
          desc: "Mark, Rowling, Esther",
          profit: "+82$",
          img: "media/svg/misc/006-plurk.svg",
        },
        {
          title: "Popular Authors",
          desc: "Randy, Steve, Mike",
          profit: "+280$",
          img: "media/svg/misc/015-telegram.svg",
        },
        {
          title: "New Users",
          desc: "John, Pat, Jimmy",
          profit: "+4500$",
          img: "media/svg/misc/003-puzzle.svg",
        },
        {
          title: "Active Customers",
          desc: "Sandra, Tim, Louis",
          profit: "+4500$",
          img: "media/svg/misc/005-bebo.svg",
        },
        {
          title: "Bestseller Theme",
          desc: "Sandra, Tim, Louis",
          profit: "+4500$",
          img: "media/svg/misc/014-kickstarter.svg",
        },
      ],
    };
  },
  methods: {
    goToDetails(deviceVersion, token) {
      console.log("goToDetails", deviceVersion, token);
      switch (deviceVersion) {
        case null:
          this.$router.push({
            name: "device-generic",
            params: {
              token: token,
            },
          });
          break;
        case "camv1":
          this.$router.push({
            name: "device-camera",
            params: {
              token: token,
            },
          });
          break;
        case "sfir":
        case "sfir_alpha0":
          this.$router.push({
            name: "device-sfir",
            params: {
              token: token,
            },
          });
          break;
        case "thermostatv1":
          this.$router.push({
            name: "device-thermo",
            params: {
              token: token,
            },
          });
          break;
        case "doorv1":
          this.$router.push({
            name: "device-doorlock",
            params: {
              token: token,
            },
          });
          break;
        case "seismov1":
          this.$router.push({
            name: "device-seismograph",
            params: {
              token: token,
            },
          });
          break;
        case "stonev1":
        case "suitchv3":
          this.$router.push({
            name: "device-stone",
            params: {
              token: token,
            },
          });
          break;
        case "trackerv1":
          this.$router.push({
            name: "device-tracker",
            params: {
              token: token,
            },
          });
          break;
          case "soilblessv1":
          case "templessv1":
          this.$router.push({
            name: "device-soilv1",
            params: {
              token: token,
            },
          });
          break;
      }
    },
  }
};
</script>
