<template>
  <div class="card card-custom bg-light-success gutter-b card-stretch card-shadowless ">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-success">
        {{ $t("Dashboard.RecentDeviceActivity") }}
      </h3>
      <div class="card-toolbar">
        <Dropdown5></Dropdown5>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2" style="overflow: scroll; height: 130px;">
      <div class="d-flex justify-content-center mb-3" v-if="list == null">
        <b-spinner variant="primary"> </b-spinner>
      </div>
      <v-alert v-if="list != null && list.length == 0" border="top" colored-border type="info" elevation="2">
        There are no events detected at the moment
      </v-alert>
      <template v-for="(item, i) in list">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 symbol-light-white mr-5" style="padding-bottom:7px !important;">
            <span class="symbol-label">
              <img :src="getIconPath(item.version)" alt="" />
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
            <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
              v-on:click.prevent="goToDetails(item.version, item.token)">
              {{ item.object }} / {{ item.location }}
            </a>
            <span class="text-muted">
              {{ item.description }}
            </span>
            <span class="text-muted">
              {{ item.created_at | formatDate }}
            </span>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown5 from "@/view/content/dropdown/Dropdown5.vue";
import { ICONS_TECHNICAL, ICONS } from "@/core/enums/icons.enum";
import {
  STORE_RECENT_ACTIVITY,
  FETCH_RECENT_ACTIVITY,
} from "@/core/services/store/devices.module";
export default {
  name: "suitch-recent-device-activity",
  components: {
    Dropdown5,
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      // console.log("mutation-->",mutation.type, state)
      if (mutation.type === STORE_RECENT_ACTIVITY) {
        state;
        this.list = this.$store.state.devices.activity;
      }
    });
    // create a timeout method
    const timeoutPopulateInfo = setTimeout(() => {
      console.log("repopulate");
      this.$store.dispatch(FETCH_RECENT_ACTIVITY);
      clearTimeout(timeoutPopulateInfo);
    }, 2500);
  },
  data() {
    return {
      getIconPath(deviceVersion) {
        if (deviceVersion == undefined) {
          return ICONS.STONE;
        }
        return ICONS_TECHNICAL[deviceVersion];
      },
      list: null,
    };
  },
  methods: {
    goToDetails(deviceVersion, token) {
      switch (deviceVersion) {
        case null:
          this.$router.push({
            name: "device-generic",
            params: {
              token: token,
            },
          });
          break;
        case "camv1":
          this.$router.push({
            name: "device-camera",
            params: {
              token: token,
            },
          });
          break;
        case "sfir":
          this.$router.push({
            name: "device-sfir",
            params: {
              token: token,
            },
          });
          break;
        case "thermostatv1":
          this.$router.push({
            name: "device-thermo",
            params: {
              token: token,
            },
          });
          break;
      }
    },
  },
};
</script>
